import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import StaticPage from "../../../../components/StaticPage";
import headerData from "../../../../constants/navData";
import CloseIcon from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import config from "../../../../config";
import {
  getAllCategoriesAction,
  getStemKitAction,
  deleteFeedbackAction,
  addStemKitAction,
  editStemKitAction,
} from "../../actions";
import {
  errorNotificationAction,
  successNotificationAction,
} from "../../../Notifications/actions";

import FormData from 'form-data'
import api from "../../../../utils/api";
import { ROUTE_TO_ADMIN_STEM_KITS } from "../../../../constants/routes";


const StemKitForm = ({
  getStemKit,
  onCreate,
  onEdit,
  stemKit,
  getCourses,
  Categories,
  errorNotification,
  successNotification,
  match
}, props) => {
  const [stem_kit, setStemKit] = useState()
  const [qImageUrl, setQImageUrl] = useState([]);
  const [uploadedFile, setUploadedFile] = useState([]);
  const form = useRef(null)
  const data = new FormData() 

useEffect(() => {
  getCourses()
  if (match.params.id != 'new') {
    getStemKit(match.params.id)
  }
}, [match.params.id]);  
useEffect(() => {
  if (match.params.id && match.params.id != 'new') {
    let QImageArr = []
    let UploadedArr = []
    for (let i = 0; i < 4; i++){
      if (typeof stemKit?.images_attributes[i] !== 'undefined'){
        QImageArr.push(config.REACT_APP_ROOT + stemKit?.images_attributes[i].file.url)
        UploadedArr.push(stemKit?.images_attributes[i].id)
      }
    }
    setUploadedFile(UploadedArr)
    setQImageUrl(QImageArr)
    setStemKit(stemKit)
  } else {
    setStemKit({ title: "", description: "", stem_kit_price: "", price: "" });
    setUploadedFile([])
    setQImageUrl([])
  }
}, [stemKit, match.params.id]);



const handleDeleteImage = (index) => {
  let QImageArr = [...qImageUrl]
  QImageArr.splice(index, 1)
  setQImageUrl(QImageArr)
  let UploadedArr = [...uploadedFile]
  UploadedArr.splice(index, 1)
  setUploadedFile(UploadedArr)
  if(typeof stemKit?.images_attributes[index] !== 'undefined'){
    console.log('FINED')
    let UploadedArr = [...uploadedFile]
    UploadedArr[index] = stemKit?.images_attributes[index].id
    data.append("[stem_kit][images_attributes][][id]", UploadedArr[index])
    data.append("[stem_kit][images_attributes][][_destroy]", true)
    api.put(`/stem_kits/${match.params.id}`, data)
  } else console.log("UNDEFINED")
  console.log(UploadedArr)
  console.log(uploadedFile)
}
const handleFileChange = (e, index) => {
  let QImageArr = [...qImageUrl]
  QImageArr.push(URL.createObjectURL(e.target.files[0]))
  setQImageUrl(QImageArr)
  let UploadedArr = [...uploadedFile]
  UploadedArr.push(e.target.files[0])
  setUploadedFile(UploadedArr)
  console.log(qImageUrl)
  e.target.value = null;
  console.log(UploadedArr)
  console.log(uploadedFile)
};
const handleChange = (e) => {
  setStemKit({ ...stem_kit, [e.target.name]: e.target.value })
}
const handleSubmit = (e) => {
  e.preventDefault();
  console.log(uploadedFile)
  data.append("[stem_kit][title]", stem_kit.title);
  data.append("[stem_kit][description]", stem_kit.description);
  data.append("[stem_kit][category_id]", stem_kit.category_id);
  data.append("[stem_kit][stem_kit_price]", stem_kit.stem_kit_price);
  data.append("[stem_kit][price]", stem_kit.price);
  for(let i = 0; i < 4; i++) if(typeof uploadedFile[i] !== 'number' && uploadedFile[i]) {
    console.log(`TRUE ` + i)
    data.append(`[stem_kit][images_attributes][][file]`, uploadedFile[i]);
  }
  if (match.params.id && match.params.id !== 'new') {
    onEdit(match.params.id, data).then((res) => {
      if (res.value.status == 200) {
        successNotification('Saved!');
        setTimeout(() => { window.location.replace(ROUTE_TO_ADMIN_STEM_KITS) }, 500)
      }
    });
  } else {
    onCreate(data).then((res) => {
      console.log(res.response)
      if (res.value.status == 200) {
        successNotification('Saved!');
        setTimeout(() => { window.location.replace(ROUTE_TO_ADMIN_STEM_KITS) }, 500)
      }
    });
  }
}

  return (
    <StaticPage pageClass="feedbacks_list" headerData={headerData.admin}>
      <div className="container">
        <form name = "stem_kit" id="formElem" ref={form} onSubmit={handleSubmit}>
          <div className="createStemKit__buttons">
            <Link to={ROUTE_TO_ADMIN_STEM_KITS} className="alertColor">
              Cancel
            </Link>
            <button type="submit">{match.params.id && match.params.id != 'new' ? "Edit StemKit" : "Create StemKit"}</button>
          </div>
          <div className="createStemKit">
            <div className="createStemKit__title">{match.params.id && match.params.id != 'new' ? "Edit StemKit" : "Add new StemKit"}</div>
            <div className="createStemKit__inputs">
              <div className="createStemKit__inputs__upper">
                <div className="createStemKit__inputs__upper-left">   
                  {qImageUrl.map((obj, index) => <FileForm obj = {obj} handleDeleteImage = {handleDeleteImage} index = {index} /> )} 
                  {typeof qImageUrl[3] === 'undefined' ? <>
                    <input
                        type="file"
                        name="images_attributes" onChange={(e) => handleFileChange(e)} 
                        className="quiz_image_input" accept="image/*" id={`quizImageAdd`}
                      />
                      <label className="quiz_image_input_label" htmlFor={`quizImageAdd`}>
                        <span className="quiz_image_input_span quiz_image_input_span-new">
                          <Add />
                        </span>
                      </label>
                      </> 
                    : null
                  }
                </div>            
              </div>
              <div className="createStemKit__inputs__bottom">
                  <input onChange={handleChange} placeholder="Title (max 1000 characters)" type="text" name="title" value={stem_kit?.title} />
                  <select className="input_dark" onChange={handleChange} placeholder="Related course" type="text" name="category_id" value={stem_kit?.category_id}>
                    <option disabled selected value="0">Related Course</option>
                    {Categories && Categories.map((obj) => { console.log(obj); return <option value={obj?.id}>{obj?.title}</option>})}
                  </select>
                  <div className="createStemKit__inputs__bottom-flex">
                    <input onChange={handleChange} placeholder="Online access price"type="number" name="price" value={stem_kit?.price} />
                    <input onChange={handleChange} placeholder="StemKit price"type="number" name="stem_kit_price" value={stem_kit?.stem_kit_price} />
                  </div>
                <textarea className ="input_dark" onChange={handleChange} placeholder="Description (max 1000 characters)"type="text" name="description" value={stem_kit?.description} />
              </div>

              
            </div>
          </div>
        </form>
      </div>
    </StaticPage>
  );
};





const FileForm = ({index, obj, handleDeleteImage}) => {
  return <>
    {/* <input
      type="file"
      name="images_attributes"
      onChange={(e) => fileChangeHandler(e, index)}
      className="quiz_image_input"
      accept="image/*"
      id={`quizImage${index}`}
    /> */}
    <label className="quiz_image_input_label" htmlFor={`quizImage${index}`}>
      <span className="quiz_image_input_span">
        <div
          className="q_image"
          style={{ backgroundImage: "url(" + obj + ")" }}
        ></div>
        <span
          className="quiz_image_delete"
          onClick={()=>{handleDeleteImage(index)}}
        >
          <CloseIcon />
        </span>
      </span>
    </label>
  </>
}






const mapDispatchToProps = (dispatch) => ({
  getCourses: () => dispatch(getAllCategoriesAction()),
  getStemKit: (id) => dispatch(getStemKitAction(id)),
  onCreate: (data) => dispatch(addStemKitAction(data)),
  onEdit: (id, data) => dispatch(editStemKitAction(id, data)),
  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message)),
});

const mapStateToProps = ({ admin }) => {
  const data = {
    stemKit: admin.stem_kit,
    Categories: admin.categories
  };
  return data;
};

export default connect(mapStateToProps, mapDispatchToProps)(StemKitForm);
