import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import StaticPage from "../../../components/StaticPage";
import headerData from "../../../constants/navData";
import StemKitItem from "./components/StemKitItem";
import {
  getStemKitsAction,
  deleteStemKitAction,
  addFeedbackAction,
  editFeedbackAction,
} from "../actions";
import {
  errorNotificationAction,
  successNotificationAction,
} from "../../Notifications/actions";



const StemKitsList = ({
  getStemKits,
  onDelete,
  stem_kits,
  errorNotification,
  successNotification,
}) => {
  const [popupShow, setPopupShow] = useState();

  useEffect(() => {
    getStemKits();
  }, []);

  const handlerDelete = async (id) => {
    await onDelete(id)
        .then((res) => {
            if (res.value.status == 200) {
                successNotification('StemKit was deleted.')
                getStemKits()
                setPopupShow()
            }
        })
        .catch((error) => {
            errorNotification(error)
        })
  };

  return (
    <StaticPage pageClass="feedbacks_list" headerData={headerData.admin}>
      <div className="container">
        <table className="feedbacks_table">
            {stem_kits
              && stem_kits.map((item, index) => {
                  return (
                    <StemKitItem
                    popupShow={popupShow}
                    setPopupShow={setPopupShow}
                    item = {item}
                    index = {index}
                    handlerDelete={handlerDelete}
                    />
                  );
                })
              }
          </table>
      </div>
    </StaticPage>
  );
};

const mapDispatchToProps = (dispatch) => ({
  getStemKits: () => dispatch(getStemKitsAction()),
  onDelete: (id) => dispatch(deleteStemKitAction(id)),
  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message)),
});

const mapStateToProps = ({ admin }) => {
  const data = {
    stem_kits: admin.stem_kits,
  };
  return data;
};

export default connect(mapStateToProps, mapDispatchToProps)(StemKitsList);
