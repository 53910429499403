import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import StripeCheckout from 'react-stripe-checkout';
import { subscribeAction, getSubscriptionsAction } from './actions';
import { getCurrentUserAction } from '../Auth/actions';
import { STRIPE_KEY, TOTAL_COST, CURRENCY } from './constants';
import StaticPage from '../../components/StaticPage';
import headerData from '../../constants/navData';
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import history from '../../history'
import { loadStripe } from '@stripe/stripe-js';

const pblsh_key = 'pk_test_51Hf5iGIQPspGj7cCEt6S1Pl4kEeluS6hteJLXmB3vW041ezRzjxM766Dev0tWPVsT8UGJgIK1Wfaqu5QDq6RAuhE00QeeRgshu'
const stripePromise = loadStripe(pblsh_key)


const StripeForm = ({showModal, setShowModal, category_id, subscribe, pricePlan, TOTAL_COST, SINGLE_COST, successNotification}) => {
  const stripe = useStripe()
  const elements = useElements()

  const [completeCard, setCompleteCard] = useState(false)
  const [submitStatus, setSubmitStatus] = useState(true)

  const completeOrder = (clientSecret) => {
    stripe.retrievePaymentIntent(clientSecret)
    .then(res => {
      const paymentIntent = res.paymentIntent;
      if (paymentIntent.status === "succeeded") {
        successNotification('Your payment succeded!')
        setTimeout(() => {
          
          history.push('/');
        }, 2000)
      }
    }).then(() => {
    }).catch(res => console.log(res))
  }

  
  const handleCardChange = e => {
    if (e.complete) {
      setCompleteCard(true)
      setSubmitStatus(false)
    }
    else {
      setCompleteCard(false)
      setSubmitStatus(true)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if(completeCard) {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });    
      const payment_method = payload.paymentMethod.id
      subscribe({category_id: category_id, price_plan: pricePlan, token: payment_method}).then(async ans => {
        console.log(ans)
        if(ans.value.status === 200) {
              stripe.confirmCardPayment(ans.value.data.payment_intent_client_secret, {
                payment_method: {
                  card: elements.getElement(CardElement)
                }
              }).then(() => {
                  completeOrder(ans.value.data.payment_intent_client_secret)
                })
          }})
    } else alert('FILL THE CARD INFO')
  }

  const iframeStyles = {
    base: {
      color: "#444",
      fontSize: "16px",
      "::placeholder": {
        color: "#666"
      }
    },
    invalid: {
      color: "#f88"
    },
    complete: {
      color: "#000"
    }
  };
  const cardElementOpts = {
    style: iframeStyles,
    hidePostalCode: true
  }
  return(
    <form onSubmit={handleSubmit}>
      <div className={`buyModal ${showModal ? 'active' : ''}`} onClick={() => setShowModal(false)}>
        <form className="buyModal__form" onClick={e => e.stopPropagation()}>
          <div className="buyModal__input">
            <CardElement onChange={handleCardChange} options={cardElementOpts}/>
          </div>
          <input disabled={submitStatus} className="buyModal__submit" type="submit" value={`Pay ${pricePlan === 0 ? SINGLE_COST / 100 : TOTAL_COST / 100} €`} />
        </form>
      </div>
    </form>
  )
}





const PaymentsInfo = ({ user, subscribe, getSubscriptions, transactions, subscribed, getCurrentUser }) => {
  const [showModal, setShowModal] = useState(false)
  useEffect(() => {
    getSubscriptions();
  }, []);
  useEffect(() => {
    getSubscriptions();
    setTimeout(() => getCurrentUser(), 1000);
  }, [subscribed]);

  return (
    <StaticPage pageClass='profile' headerData={headerData.autorized}>
      <section className='payments-info'>
        <div className='payments-info-header'>
          <h2>My Payment info</h2>
        </div>
        <div className='payments-info-current-plan'>
          <p className='payments-info-current-plan-header'>Current Plan</p>
          <p className='payments-info-current-plan-active'>{(user && user.subscription) ? `Your subscription till: ${user.subscription_end_time}` : ''}</p>
        </div>
        <div className='payments-info-devider' />
        <div className='payments-info-current-plan'>
          <p className='payments-info-current-plan-header'>Payment Method</p>
            <button onClick={() => setShowModal(true)} disabled={false} className={`start-quiz-btn payments-info-btn ${(user && user.subscription) ? 'start-quiz-btn-disabled' : ''}`}>
              <span className='start-quiz-btn-text'>{(user && user.subscription) ? 'Subscribed' : 'Pay 199 EUR / year'}</span>
            </button>
        </div>
        <div className='payments-info-devider' />
        <div className='payments-info-current-plan payments-info-invoicing'>
          <p className='payments-info-current-plan-header'>Invoicing</p>
          {transactions[0] ?
            <p className='payments-info-current-plan-active'>{`Last payment: ${transactions[0].start_date}`}</p> :
            null
          }
        </div>
      </section>
      <Elements stripe={stripePromise}>
        <StripeForm subscribe={subscribe} showModal={showModal} TOTAL_COST={19900} pricePlan={1} />
      </Elements>
    </StaticPage>
  );
}

const mapStateToProps = ({auth: {user}, subscriptions: {transactions, subscribed}}) => ({
  user,
  transactions,
  subscribed,
});

const mapDispatchToProps = (dispatch) => ({
  subscribe: data => dispatch(subscribeAction(data)),
  getSubscriptions: () => dispatch(getSubscriptionsAction()),
  getCurrentUser: () => dispatch(getCurrentUserAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsInfo)