import StemKits from '.';
import {getStemKitsAction, getStemKitAction} from './actions'
import { connect } from "react-redux";



const mapDispatchToProps = (dispatch) => ({
  getStemKits: () => dispatch(getStemKitsAction()),
  getStemKit: (id) => dispatch(getStemKitAction(id))
});
  
const mapStateToProps = (state) => ({
  stem_kits: state.stemKits.stem_kits,
  stem_kit: state.stemKits.stem_kit
});
  

const StemKitsContainer = connect(mapStateToProps, mapDispatchToProps)(StemKits);
export default StemKitsContainer