import { createAction } from "redux-actions";

import {
  CREATE_CATEGORY,
  GET_CATEGORY,
  DELETE_CATEGORY,
  EDIT_CATEGORY,
  GET_CATEGORIES,
  GET_LESSON,
  ADD_LESSON,
  DELETE_LESSON,
  EDIT_LESSON,
  GET_USERS,
  DELETE_USER,
  GET_FEEDBACKS,
  CREATE_FEEDBACK,
  EDIT_FEEDBACK,
  DELETE_FEEDBACK,
  GET_FEEDBACK,
  GET_STEM_KIT,
  GET_STEM_KITS,
  CREATE_STEM_KIT,
  EDIT_STEM_KIT,
  DELETE_STEM_KIT,
  GET_ORDER,
  GET_ORDERS,
  DELETE_ORDER,
  ADD_ORDER,
  EDIT_ORDER,
  GET_ALL_CATEGORIES
} from "./constants";
import {
  createCategoryApi,
  getCategoryApi,
  deleteCategoryApi,
  editCategoryApi,
  getCategoriesApi,
  getLessonApi,
  addLessonApi,
  deleteLessonApi,
  editLessonApi,
  getUsersApi,
  deleteUserApi,
  getFeedbacksApi,
  addFeedbackApi,
  editFeedbackApi,
  deleteFeedbackApi,
  getFeedbackApi,
  getStemKitApi,
  getStemKitsApi,
  addStemKitApi,
  editStemKitApi,
  deleteStemKitApi,
  getOrderApi,
  getOrdersApi,
  deleteOrderApi,
  addOrderApi,
  editOrderApi
} from "./api";

export const createCategoryAction = createAction(
  CREATE_CATEGORY,
  async (data) => createCategoryApi(data)
);
export const getCategoryAction = createAction(GET_CATEGORY, async (id) =>
  getCategoryApi(id)
);
export const editCategoryAction = createAction(
  EDIT_CATEGORY,
  async (id, data) => editCategoryApi(id, data)
);
export const deleteCategoryAction = createAction(DELETE_CATEGORY, async (id) =>
  deleteCategoryApi(id)
);
export const getCategoriesAction = createAction(GET_CATEGORIES, async () =>
  getCategoriesApi()
);
export const getAllCategoriesAction = createAction(GET_ALL_CATEGORIES, async () =>
  getCategoriesApi()
);
export const getLessonAction = createAction(GET_LESSON, async (id) =>
  getLessonApi(id)
);
export const addLessonAction = createAction(ADD_LESSON, async (data) =>
  addLessonApi(data)
);
export const deleteLessonAction = createAction(DELETE_LESSON, async (id) =>
  deleteLessonApi(id)
);
export const editLessonAction = createAction(EDIT_LESSON, async (id, data) =>
  editLessonApi(id, data)
);
export const getUsersAction = createAction(GET_USERS, async (params) =>
  getUsersApi(params)
);
export const deleteUserAction = createAction(DELETE_USER, async (id) =>
  deleteUserApi(id)
);
export const getFeedbacksAction = createAction(GET_FEEDBACKS, async () =>
  getFeedbacksApi()
);
export const getFeedbackAction = createAction(GET_FEEDBACK, async (id) =>
  getFeedbackApi(id)
);
export const addFeedbackAction = createAction(CREATE_FEEDBACK, async (params) =>
  addFeedbackApi(params)
);
export const editFeedbackAction = createAction(EDIT_FEEDBACK, async (id, data) =>
  editFeedbackApi(id, data)
);
export const deleteFeedbackAction = createAction(DELETE_FEEDBACK, async (id) =>
  deleteFeedbackApi(id)
);
export const getStemKitsAction = createAction(GET_STEM_KITS, async () =>
  getStemKitsApi()
);
export const getStemKitAction = createAction(GET_STEM_KIT, async (id) =>
  getStemKitApi(id)
);
export const addStemKitAction = createAction(CREATE_STEM_KIT, async (data) =>
  addStemKitApi(data)
);
export const editStemKitAction = createAction(EDIT_STEM_KIT, async (id, data) =>
  editStemKitApi(id, data)
);
export const deleteStemKitAction = createAction(DELETE_STEM_KIT, async (id) =>
  deleteStemKitApi(id)
);
export const getOrdersAction = createAction(GET_ORDERS, async () =>
  getOrdersApi()
)
export const getOrderAction = createAction(GET_ORDER, async (id) =>
  getOrderApi(id)
)
export const addOrderAction = createAction(ADD_ORDER, async (data) =>
  addOrderApi(data)
)
export const deleteOrderAction = createAction(DELETE_ORDER, async (id) =>
  deleteOrderApi(id)
)
export const editOrderAction = createAction(EDIT_ORDER, async (id, data) =>
  editOrderApi(id, data)
)