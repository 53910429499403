import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import StaticPage from "../../../components/StaticPage";
import headerData from "../../../constants/navData";
import OrderItem from './components/OrderItem'
import {
    getOrdersAction,
    deleteOrderAction,
    onDelete
} from "../actions";
import {
  errorNotificationAction,
  successNotificationAction,
} from "../../Notifications/actions";

const OrdersList = ({
    getOrders,
    orders,
    onDelete,
    successNotification,
    errorNotification
}) => {
    const [popupShow, setPopupShow] = useState();
    useEffect(()=> {
        getOrders()
    }, [])
    const handlerDelete = async (id) => {
        await onDelete(id)
            .then((res) => {
                if (res.value.status == 200) {
                    successNotification('Order was deleted.')
                    getOrders()
                    setPopupShow()
                }
            })
            .catch((error) => {
                errorNotification(error)
            })
      };

    return(        
        <StaticPage pageClass="feedbacks_list" headerData={headerData.admin}>
            <div className="container">
                <div className='container orderlist'>
                    <div className="orderlist__element orderlist__element-0">
                        <p className='feedback_name'>Title</p>
                    </div>
                    <div className="orderlist__element orderlist__element-1">
                        <p className='feedback_created'>Price</p>
                    </div>
                    <div className="orderlist__element orderlist__element-2">
                        <p className='feedback_created'>Status</p>
                    </div>
                    <div className="orderlist__element orderlist__element-3">
                        <p className='feedback_created'>Created</p>
                    </div>
                    <div className="orderlist__element orderlist__element-4">
                    </div>
                </div>
                <span className="line"></span>
                <table className="feedbacks_table">
                    {orders
                        && orders.map((item, index) => {
                            return (
                            <OrderItem
                            popupShow={popupShow}
                            setPopupShow={setPopupShow}
                            item = {item}
                            index = {index}
                            handlerDelete={handlerDelete}
                            />
                            );
                        })
                    }
                </table>
            </div>
        </StaticPage>
    )

}

const mapDispatchToProps = (dispatch) => ({
  getOrders: () => dispatch(getOrdersAction()),
  onDelete: (id) => dispatch(deleteOrderAction(id)),
  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message)),
});

const mapStateToProps = ({ admin }) => {
  const data = {
    orders: admin.orders,
  };
  return data;
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersList);