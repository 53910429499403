import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { STRIPE_KEY, TOTAL_COST, SINGLE_COST, CURRENCY } from '../../containers/PaymentsInfo/constants';
import { loadStripe } from '@stripe/stripe-js'
import {connect} from 'react-redux'
import {
  Elements,
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { subscribeAction } from '../../containers/PaymentsInfo/actions';
import {
  errorNotificationAction,
  successNotificationAction,
} from "../../containers/Notifications/actions";

import { routeToCourse } from '../../constants/routes';
import history from '../../history';
const pblsh_key = 'pk_test_51Hf5iGIQPspGj7cCEt6S1Pl4kEeluS6hteJLXmB3vW041ezRzjxM766Dev0tWPVsT8UGJgIK1Wfaqu5QDq6RAuhE00QeeRgshu'
const stripePromise = loadStripe(pblsh_key)


const StripeForm = ({showModal, setShowModal, category_id, subscribe, pricePlan, TOTAL_COST, SINGLE_COST, successNotification}) => {
  const stripe = useStripe()
  const elements = useElements()

  const [completeCard, setCompleteCard] = useState(false)
  const [submitStatus, setSubmitStatus] = useState(true)

  const completeOrder = (clientSecret) => {
    stripe.retrievePaymentIntent(clientSecret)
    .then(res => {
      const paymentIntent = res.paymentIntent;
      if (paymentIntent.status === "succeeded") {
        successNotification('Your payment succeded!')
        setTimeout(() => {
          
          history.push(routeToCourse(category_id));
        }, 2000)
      }
    }).then(() => {
    }).catch(res => console.log(res))
  }

  
  const handleCardChange = e => {
    if (e.complete) {
      setCompleteCard(true)
      setSubmitStatus(false)
    }
    else {
      setCompleteCard(false)
      setSubmitStatus(true)
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if(completeCard) {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });    
      const payment_method = payload.paymentMethod.id
      subscribe({category_id: category_id, price_plan: pricePlan, token: payment_method}).then(async ans => {
        console.log(ans)
        if(ans.value.status === 200) {
              stripe.confirmCardPayment(ans.value.data.payment_intent_client_secret, {
                payment_method: {
                  card: elements.getElement(CardElement)
                }
              }).then(() => {
                  completeOrder(ans.value.data.payment_intent_client_secret)
                })
          }})
    } else alert('FILL THE CARD INFO')
  }

  const iframeStyles = {
    base: {
      color: "#444",
      fontSize: "16px",
      "::placeholder": {
        color: "#666"
      }
    },
    invalid: {
      color: "#f88"
    },
    complete: {
      color: "#000"
    }
  };
  const cardElementOpts = {
    style: iframeStyles,
    hidePostalCode: true
  }
  return(
    <form onSubmit={handleSubmit}>
      <div className={`buyModal ${showModal ? 'active' : ''}`} onClick={() => setShowModal(false)}>
        <form className="buyModal__form" onClick={e => e.stopPropagation()}>
          <div className="buyModal__input">
            <CardElement onChange={handleCardChange} options={cardElementOpts}/>
          </div>
          <input disabled={submitStatus} className="buyModal__submit" type="submit" value={`Pay ${pricePlan === 0 ? SINGLE_COST / 100 : TOTAL_COST / 100} €`} />
        </form>
      </div>
    </form>
  )
}


const PaymentModal = ({ id, courseName, user, onToken, onTokenSingle, open, hideModal, subscribe, successNotification }) => {
  const [showModal, setShowModal] = useState(false)
  const [pricePlan, setPricePlan] = useState()

  const useStyles = makeStyles(() => ({
    paper: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      width: '100%',
      maxWidth: 700,
      backgroundColor: '#fff',
      borderRadius: 10,
      outline: 0,
      padding: '20px',
      transform: 'translate(-50%,-50%)'
    },
  }));

  const classes = useStyles();

  return (
    <div >

      {open ? (
        <div
          className='custom_modal'
        >
          <div className={classes.paper}>
            <button type='button' className='close_payment_modal' onClick={hideModal}>x</button>
            <h3 className='price_modal_header mb-30'>Pricing Per Educator: </h3>
            <div className='justify-center row'>
              <div className='price_modal_item'>
                <h4 className='price_item_header'>{courseName} module</h4>
                <p className='price_modal_label'>Price for individual module:</p>
                <p className='price_modal_amount'>{`${SINGLE_COST / 100} ${CURRENCY}`}</p>
                <div className='checkout_wrapper'>
              
                  <button onClick={() => {setShowModal(true); setPricePlan(0)}} disabled={false} className={`price_modal_btn start-quiz-btn payments-info-btn ${(user && user.subscription) ? 'start-quiz-btn-disabled' : ''}`}>
                    <span className='start-quiz-btn-text'>{(user && user.subscription) ? 'Subscribed' : `Pay ${SINGLE_COST / 100} ${CURRENCY} /year`}</span>
                  </button>
                </div>
              </div>
              <div className='price_modal_item'>
                <h4 className='price_item_header'>
                  Buy all 5 modules
                </h4>
                <p className='price_modal_label'>Price for all modules:</p>
                <p className='price_modal_old_amount'>{`${SINGLE_COST * 5 / 100} ${CURRENCY}`}</p>
                <p className='price_modal_amount'>{`${TOTAL_COST / 100} ${CURRENCY}`}</p>
                  <button onClick={() => {setShowModal(true); setPricePlan(1)}} disabled={false} className={`price_modal_btn start-quiz-btn payments-info-btn ${(user && user.subscription) ? 'start-quiz-btn-disabled' : ''}`}>
                    <span className='start-quiz-btn-text'>{(user && user.subscription) ? 'Subscribed' : `Pay ${TOTAL_COST / 100} ${CURRENCY} /year`}</span>
                  </button>

              </div>
            </div>
          </div>
        </div>
      ) : null}
      <Elements stripe={stripePromise}>
        <StripeForm
        SINGLE_COST={SINGLE_COST} TOTAL_COST={TOTAL_COST} subscribe={subscribe}
        pricePlan={pricePlan} category_id={id} showModal={showModal}
        setShowModal={setShowModal} successNotification={successNotification}
        />
      </Elements>
    </div>
  )
};

const mapDispatchToProps = dispatch => ({
  subscribe: data => dispatch(subscribeAction(data)),
  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message))
})
export default connect (null, mapDispatchToProps)(PaymentModal)