import api from "../../utils/api";

export const createCategoryApi = (data) => api.post("/categories", data);
export const getCategoryApi = (id) => api.get(`/categories/${id}?admin=true`);
export const deleteCategoryApi = (id) => api.delete(`/categories/${id}`);
export const editCategoryApi = (id, data) => api.put(`/categories/${id}`, data);
export const getCategoriesApi = () => api.get("/categories/?admin=true");
export const getLessonApi = (id) => api.get(`/tutorials/${id}`);
export const addLessonApi = (data) => api.post("/tutorials", data);
export const deleteLessonApi = (id) => api.delete(`/tutorials/${id}`);
export const editLessonApi = (id, data) => api.put(`/tutorials/${id}`, data);
export const getUsersApi = (params) => api.get("/users", { params });
export const deleteUserApi = (id) => api.delete(`/users/${id}`);
export const getFeedbacksApi = () => api.get("/testimonials");
export const getFeedbackApi = (id) => api.get(`/testimonials/${id}`);
export const addFeedbackApi = (params) => api.post("/testimonials", params);
export const editFeedbackApi = (id, data) =>
  api.put(`/testimonials/${id}`, data);
export const deleteFeedbackApi = (id) => api.delete(`/testimonials/${id}`);
export const getStemKitsApi = () => api.get(`/stem_kits`);
export const getStemKitApi = (id) => api.get(`/stem_kits/${id}`);
export const editStemKitApi = (id, data) =>
  api.put(`/stem_kits/${id}`, data);
export const addStemKitApi = (data) => api.post("/stem_kits", data);
export const deleteStemKitApi = (id) => api.delete(`/stem_kits/${id}`);
export const getOrdersApi = () => api.get(`/orderings`)
export const addOrderApi = (data) => api.post(`/orderings`, data)
export const getOrderApi = (id) => api.get(`/orderings/${id}`)
export const deleteOrderApi = (id) => api.delete(`/orderings/${id}`)
export const editOrderApi = (id, data) => api.put(`orderings/${id}`, data)
