import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import StaticPage from "../../../../components/StaticPage";
import headerData from "../../../../constants/navData";
import {
    addOrderAction,
  getOrderAction, getStemKitsAction,
  editOrderAction
} from "../../actions";
import {
  errorNotificationAction,
  successNotificationAction,
} from "../../../Notifications/actions";
import { routeToOrder, ROUTE_TO_ADMIN_ORDERS } from "../../../../constants/routes"


const OrderForm = ({
    match,
    getOrder,
    Order,
    getStemKits,
    stem_kits,
    onCreate,
    onEdit,
    successNotification
}) => {
    const [order, setOrder] = useState()
    
    useEffect(() => {
        getStemKits()
        if (match.params.id != 'new') {
          getOrder(match.params.id)
        }
    }, [match.params.id])
    useEffect(() =>{
        let data
        if (match.params.id && match.params.id != 'new') {
            console.log("NO NEW")
            data = Order
            if (data?.status === 'pending') data = {...data, status: 0}
            else if (data?.status === 'in_progres') data = {...data, status: 1}
            else if (data?.status === 'paid') data = {...data, status: 2}
            else if (data?.status === 'arrived') data = {...data, status: 3}
            else if (data?.status === 'failed') data = {...data, status: 4}
            setOrder(data)
        } else {
            console.log("NEW")
            data = {adress: "", apartment: "", count: "", email: "", first_name: "", postal_code: '',
            last_name: "", phone: "", status: null, stem_kit_id: null, ordering_type: null}
            setOrder(data)
        }
    }, [Order, match.params.id])

    
    const handleChange = e => {
        let name = e.target.name, value = e.target.value
        if(name === 'stem_kit_id' || name === 'status' || name === 'ordering_type' || name === 'count') 
        value = Number(e.target.value)
        setOrder({ ...order, [name]: value})
        console.log(order)
    }

    const handleSubmit = e => {
        e.preventDefault()
        let data = {ordering: order}
        console.log(data)
        if (match.params.id && match.params.id !== 'new') {
            onEdit(match.params.id, data).then((res) => {
                if(res.value.status == 200) {
                    successNotification("Changed!")
                    setTimeout(() => window.location.replace(routeToOrder(match.params.id + '/view')), 500)
                }
            })
        } else {
            onCreate(data).then((res) => {
                if(res.value.status == 200) {
                    successNotification('Added!')
                    setTimeout(() => window.location.replace(ROUTE_TO_ADMIN_ORDERS), 500)
                }
            })
        }
    }
    
    return (
        <StaticPage pageClass="feedbacks_list" headerData={headerData.admin}>
        <div className="container">
            <form onSubmit={handleSubmit} className="orderForm">
                <div className="orderForm__buttons">
                
                {typeof match.params.view === 'undefined' ? match.params.id !== 'new' ? <Link to={routeToOrder(match.params.id) + '/view'} className="alertColor">Cancel</Link> : <Link to={ROUTE_TO_ADMIN_ORDERS} className="alertColor">Cancel</Link> : <Link to={ROUTE_TO_ADMIN_ORDERS} className="alertColor">GO BACK</Link>}
                {typeof match.params.view === 'undefined' ? <button type="submit">{match.params.id && match.params.id != 'new' ? "Edit Order" : "Create Order"}</button> : <Link to={routeToOrder(match.params.id)}>Change this order</Link>}
                </div>
                <div className="orderForm__create">
                    <div className="orderForm__create__title">Ordering</div>
                        {
                        typeof match.params.view === 'undefined' ? 
                            <div className="orderForm__create__data">
                                <p className="orderForm__create__data__text">Product info</p>
                                <div className="orderForm__create__data__input">
                                    <p className="orderForm__create__data__input__label">Title of product</p>
                                    <select onChange={e => handleChange(e)} required name="stem_kit_id" value={order?.stem_kit_id} type="select" className="input_dark">
                                        {stem_kits && stem_kits.map((item) => <option selected value={item.id}>{item.title}</option>)}
                                    </select>
                                </div>
                                <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                    <p className="orderForm__create__data__input__label">Type</p>
                                    <select name="ordering_type" value={order?.ordering_type} onChange={e => handleChange(e)} type="select" required className="input_dark">
                                        <option value="1">Online access plus StemKit</option>
                                        <option selected value="0">StemKit</option>
                                    </select>
                                </div>
                                <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                    <p className="orderForm__create__data__input__label">Amount</p>
                                    <input name="count" value={order?.count} type="number" onChange={e => handleChange(e)} required className="input_dark" />
                                </div>
                                <div className="orderForm__create__data__input">
                                    <p className="orderForm__create__data__input__label">Status of the order</p>
                                    <select name="status" value={order?.status} type="select" onChange={e => handleChange(e)} required className="input_dark">
                                        <option selected value="0">Pending</option>
                                        <option value="1">In progress</option>
                                        <option value="2">Paid</option>
                                        <option value="3">Arrived</option>
                                        <option value="4">Failed</option>
                                    </select>
                                </div>
                                <p className="orderForm__create__data__text">Customer info</p>
                                <div className="orderForm__create__data__input">
                                    <p className="orderForm__create__data__input__label">E-mail</p>
                                    <input onChange={e => handleChange(e)} required name="email" value={order?.email} type="email" className="input_dark" />
                                </div>
                                <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                    <p className="orderForm__create__data__input__label">First Name</p>
                                    <input onChange={e => handleChange(e)} required name="first_name" value={order?.first_name} type="text" className="input_dark" />
                                </div>
                                <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                    <p className="orderForm__create__data__input__label">Last Name</p>
                                    <input onChange={e => handleChange(e)} required name="last_name" value={order?.last_name} type="text" className="input_dark" />
                                </div>
                                <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                    <p className="orderForm__create__data__input__label">Adress</p>
                                    <input onChange={e => handleChange(e)} required name="adress" value={order?.adress} type="text" className="input_dark" />
                                </div>
                                <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                    <p className="orderForm__create__data__input__label">Apartment, suite, etc. (optional)</p>
                                    <input onChange={e => handleChange(e)} name="apartment" value={order?.apartment} type="text" className="input_dark" />
                                </div>
                                <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                    <p className="orderForm__create__data__input__label">Postal code</p>
                                    <input onChange={e => handleChange(e)} required name="postal_code" value={order?.postal_code} type="number" className="input_dark" />
                                </div>
                                <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                    <p className="orderForm__create__data__input__label">Phone</p>
                                    <input onChange={e => handleChange(e)} required name="phone" value={order?.phone} type="number" className="input_dark" />
                                </div>
                            </div>                            
                        :
                            <div className="orderForm__create__data">                                
                                <div className="orderForm__create__data__item">
                                    <span className="orderForm__create__data__item__count">{order?.count}X</span>
                                    <span className="orderForm__create__data__item__title">{order?.stem_kit.title}</span>
                                    <span className="orderForm__create__data__item__price">&euro;{order?.ordering_type === 'buy_stem_kit' ? order?.stem_kit.stem_kit_price : order?.ordering_type === 'buy_stem_kit_and_course' ? order?.stem_kit.price + order?.stem_kit.stem_kit_price : null}</span>
                                </div>
                                <div className="orderForm__create__data__input orderForm__create__data__input">
                                    <p className="orderForm__create__data__input__label">Status of the order</p>
                                    <input readOnly name="status" required className="input_dark" value={order?.status == 0 ? 'Pending' :
                                    order?.status == 1 ? 'In Progress' :
                                    order?.status == 2 ? 'Paid' :
                                    order?.status == 3 ? 'Arrived' :
                                    order?.status == 4 ? 'Failed' : null
                                    } />
                                </div>                                
                                <div className="orderForm__create__data__input orderForm__create__data__input">
                                    <p className="orderForm__create__data__input__label">Email</p>
                                    <input readOnly required name="email" value={order?.email} type="text" className="input_dark" />
                                </div>
                                <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                    <p className="orderForm__create__data__input__label">First Name</p>
                                    <input readOnly required name="first_name" value={order?.first_name} type="text" className="input_dark" />
                                </div>
                                <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                    <p className="orderForm__create__data__input__label">Last Name</p>
                                    <input readOnly required name="last_name" value={order?.last_name} type="text" className="input_dark" />
                                </div>
                                <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                    <p className="orderForm__create__data__input__label">Adress</p>
                                    <input readOnly required name="adress" value={order?.adress} type="text" className="input_dark" />
                                </div>
                                <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                    <p className="orderForm__create__data__input__label">Apartment</p>
                                    <input readOnly required name="apartment" value={order?.apartment} type="text" className="input_dark" />
                                </div>
                                <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                    <p className="orderForm__create__data__input__label">Postal code</p>
                                    <input readOnly required name="postal_code" value={order?.postal_code} type="text" className="input_dark" />
                                </div>
                                <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                    <p className="orderForm__create__data__input__label">Phone</p>
                                    <input readOnly required name="phone" value={order?.phone} type="text" className="input_dark" />
                                </div>
                            </div>
                        }
                        
                </div>
            </form>
        </div>
        </StaticPage>
    )
}

const mapDispatchToProps = (dispatch) => ({    
    getOrder: (id) => dispatch(getOrderAction(id)),
    getStemKits: () => dispatch(getStemKitsAction()),
    onCreate: (data) => dispatch(addOrderAction(data)),
    onEdit : (id, data) => dispatch(editOrderAction(id, data)),
    successNotification: (message) => dispatch(successNotificationAction(message)),
    errorNotification: (message) => dispatch(errorNotificationAction(message)),
  });
  
  const mapStateToProps = ({ admin }) => {
    const data = {
      Order: admin.order,
      stem_kits: admin.stem_kits
    };
    return data;
  };
  export default connect(mapStateToProps, mapDispatchToProps)(OrderForm)