export const CREATE_CATEGORY = "admin/create_category";
export const GET_CATEGORY = "admin/get_category";
export const DELETE_CATEGORY = "admin/delete_category";
export const EDIT_CATEGORY = "admin/edit_category";
export const GET_CATEGORIES = "admin/get_categories";
export const GET_ALL_CATEGORIES = "admin/get_all_categories";
export const GET_LESSON = "admin/get_lesson";
export const ADD_LESSON = "admin/add_lesson";
export const DELETE_LESSON = "admin/delete_lesson";
export const EDIT_LESSON = "admin/edit_lesson";
export const GET_USERS = "admin/get_cusers";
export const DELETE_USER = "admin/delete_cuser";
export const GET_FEEDBACKS = "admin/get_feedbacks";
export const GET_FEEDBACK = "admin/get_feedback";
export const CREATE_FEEDBACK = "admin/create_feedback";
export const EDIT_FEEDBACK = "admin/edit_feedback";
export const DELETE_FEEDBACK = "admin/delete_feedback";
export const GET_STEM_KITS = "admin/get_stem_kits";
export const GET_STEM_KIT = "admin/get_stem_kit";
export const CREATE_STEM_KIT = "admin/create_stem_kit"
export const EDIT_STEM_KIT = "admin/edit_stem_kit"
export const DELETE_STEM_KIT = "admin/delete_stem_kit"
export const GET_ORDERS = "admin/get_orders"
export const GET_ORDER = "admin/get_order"
export const ADD_ORDER = "admin/add_order"
export const DELETE_ORDER = "admin/delete_order"
export const EDIT_ORDER = "admin/edit_order"
