import React from 'react'
import { Link } from "react-router-dom";
import { routeToOrder } from '../../../../constants/routes'
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import Create from '@material-ui/icons/Create';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const OrderItem = (props) => {

    const handleClickOpen = () => {
        props.setPopupShow(props.index);
    };

    const handleClose = () => {
        props.setPopupShow();
    };

    const handlePreview = () => {
        window.location.replace(routeToOrder(props.item.id) + '/view');
    }

    return (
        <tr className='container feedbacklist_item'>
            <td onClick={handlePreview}>
                <p className='feedback_name'>{props.item.stem_kit.title}</p>
            </td>
            <td onClick={handlePreview}>
                <p className='feedback_created'>&euro;{props.item.ordering_type === 'buy_stem_kit' ? props.item.stem_kit.stem_kit_price : props.item.ordering_type === 'buy_stem_kit_and_course' ? props.item.stem_kit.stem_kit_price + props.item.stem_kit.price : null}</p>
            </td>
            <td onClick={handlePreview}>
                <p className='feedback_created order_status'>{props.item.status === 'in_progres' ? 'In progress' : props.item.status}</p>
            </td>
            <td onClick={handlePreview}>
                <p className='feedback_created'>{props.item.created_at}</p>
            </td>
            
            <td className='feedback_item_actions'>
                <Link to={routeToOrder(props.item.id)} className="feedback_icon edit"><Create /></Link>
                <a href='#' className="feedback_icon" onClick={handleClickOpen} ><DeleteForeverIcon /></a>
            </td>

            <Dialog
                open={props.popupShow == props.index}
                fullWidth={true}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Delete Order?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>
                        Disagree
                    </Button>
                    <Button onClick={() => props.handlerDelete(props.item.id)} color="secondary">
                        Delete
                </Button>
                </DialogActions>
            </Dialog>
        </tr>
    )
}


export default OrderItem
