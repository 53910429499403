import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import StaticPage from "../../../../components/StaticPage";
import headerData from "../../../../constants/navData";
import {
    addOrderAction,
  getOrderAction, getStemKitsAction,
  editOrderAction
} from "../../actions";
import {
  errorNotificationAction,
  successNotificationAction,
} from "../../../Notifications/actions";
import { ROUTE_TO_ADMIN_ORDERS } from "../../../../constants/routes"

const OrderPreview = ({
    match,
    getOrder,
    Order,
    getStemKits,
    stem_kits,
    onCreate,
    onEdit,
    successNotification
}) => {
const [order, setOrder] = useState()

useEffect(() => {
    getStemKits()
    if (match.params.id != 'new') {
      getOrder(match.params.id)
    }
}, [match.params.id])
useEffect(() =>{
    if (match.params.id && match.params.id != 'new') {
        let data = Order
        if (data?.status === 'pending') data = {...data, status: 0}
        else if (data?.status === 'in_progress') data = {...data, status: 1}
        else if (data?.status === 'paid') data = {...data, status: 2}
        else if (data?.status === 'arrived') data = {...data, status: 3}
        else if (data?.status === 'failed') data = {...data, status: 4}

        setOrder(data)
    }
}, [Order, match.params.id])


const handleChange = e => {
    let name = e.target.name, value = e.target.value
    if(name === 'stem_kit_id' || name === 'status' || name === 'transaction_type' || name === 'amount') 
    value = Number(e.target.value)
    setOrder({ ...order, [name]: value})
    console.log(order)
}

const handleSubmit = e => {
    e.preventDefault()
    let data = {ordering: order}
    console.log(data)
    if (match.params.id && match.params.id !== 'new') {
        onEdit(match.params.id, data).then((res) => {
            if(res.value.status == 200) {
                successNotification("Changed!")
                setTimeout(() => window.location.replace(ROUTE_TO_ADMIN_ORDERS), 500)
            }
        })
    } else {
        onCreate(data).then((res) => {
            if(res.value.status == 200) {
                successNotification('Added!')
                setTimeout(() => window.location.replace(ROUTE_TO_ADMIN_ORDERS), 500)
            }
        })
    }
}

    return (
        <StaticPage pageClass="feedbacks_list" headerData={headerData.admin}>
            <div className="container">
                <form onSubmit={handleSubmit} className="orderForm">
                    <div className="orderForm__buttons">
                    <Link to={ROUTE_TO_ADMIN_ORDERS} className="alertColor">
                        Cancel
                    </Link>
                    <button type="submit">{match.params.id && match.params.id != 'new' ? "Edit Order" : "Create Order"}</button>
                    </div>
                    <div className="orderForm__create">
                        <div className="orderForm__create__title">Ordering</div>
                        <div className="orderForm__create__data">
                            <p className="orderForm__create__data__text">Product info</p>
                            <div className="orderForm__create__data__input">
                                <p className="orderForm__create__data__input__label">Title of product</p>
                                <select disabled required name="stem_kit_id" value={order?.stem_kit_id} type="select" className="input_dark">
                                    {stem_kits && stem_kits.map((item) => <option value={item.id}>{item.title}</option>)}
                                </select>
                            </div>
                            <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                <p className="orderForm__create__data__input__label">Type</p>
                                <select disabled name="transaction_type" value={order?.transaction_type} type="select" required className="input_dark">
                                    <option value="4">Online access plus StemKit</option>
                                    <option value="3">StemKit</option>
                                </select>
                            </div>
                            <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                <p className="orderForm__create__data__input__label">Amount</p>
                                <input readonly required name="count" type="number" value={order?.count} className="input_dark" />
                            </div>
                            <div className="orderForm__create__data__input">
                                <p className="orderForm__create__data__input__label">Status of the order</p>
                                <select disabled name="status" value={order?.status} type="select" required className="input_dark">
                                    <option value="0">Pending</option>
                                    <option value="1">In progress</option>
                                    <option value="2">Paid</option>
                                    <option value="3">Arrived</option>
                                    <option value="4">Failed</option>
                                </select>
                            </div>
                            <p className="orderForm__create__data__text">Customer info</p>
                            <div className="orderForm__create__data__input">
                                <p className="orderForm__create__data__input__label">E-mail</p>
                                <input readonly required name="email" value={order?.email} type="email" className="input_dark" />
                            </div>
                            <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                <p className="orderForm__create__data__input__label">First Name</p>
                                <input readonly required name="first_name" value={order?.first_name} type="text" className="input_dark" />
                            </div>
                            <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                <p className="orderForm__create__data__input__label">Last Name</p>
                                <input readonly required name="last_name" value={order?.last_name} type="text" className="input_dark" />
                            </div>
                            <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                <p className="orderForm__create__data__input__label">Adress</p>
                                <input readonly required name="adress" value={order?.adress} type="text" className="input_dark" />
                            </div>
                            <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                <p className="orderForm__create__data__input__label">Apartment, suite, etc. (optional)</p>
                                <input readonly name="apartment" value={order?.apartment} type="text" className="input_dark" />
                            </div>
                            <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                <p className="orderForm__create__data__input__label">Postal code</p>
                                <input readonly required name="postal_code" value={order?.postal_code} type="number" className="input_dark" />
                            </div>
                            <div className="orderForm__create__data__input orderForm__create__data__input-flex">
                                <p className="orderForm__create__data__input__label">Phone</p>
                                <input readonly required name="phone" value={order?.phone} type="tel" className="input_dark" />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </StaticPage>
    )
}


const mapDispatchToProps = (dispatch) => ({    
    getOrder: (id) => dispatch(getOrderAction(id)),
    getStemKits: () => dispatch(getStemKitsAction()),
    onCreate: (data) => dispatch(addOrderAction(data)),
    onEdit : (id, data) => dispatch(editOrderAction(id, data)),
    successNotification: (message) => dispatch(successNotificationAction(message)),
    errorNotification: (message) => dispatch(errorNotificationAction(message)),
  });
  
  const mapStateToProps = ({ admin }) => {
    const data = {
      Order: admin.order,
      stem_kits: admin.stem_kits
    };
    return data;
  };

export default connect(mapStateToProps, mapDispatchToProps)(OrderPreview)
