import React, {useEffect} from 'react'
import StemKitItem from './item'
import StaticPage from "../../components/StaticPage";
import headerData from "../../constants/navData";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Link } from "react-router-dom";
import config from "../../config";

const StemKits = (props) => {
  useEffect(() => {
    if(props.match.params.id){
    props.getStemKit(props.match.params.id)
    props.getStemKits()
    } else props.getStemKits()
    
  }, [props.match.params.id])
  const handleChange = () => props.getStemKit(props.match.params.id)
  return(
    <StaticPage
      pageClass="profile"
      headerData={props.user ? headerData.autorized : headerData.general}
    >
      <section className="section topics">
        <div className="container">
          <div className="list-title">
            <h2 className="stemkit_header">{props.match.params.order ? 'Ordering' : 'StemKit'}</h2>
            {props.match.params.order ? null : <p className="stemkit_subheader">We supply a range of optional StemKits to accompany each module.</p>}
            
          </div>
          {props.inProcess ? (
            <div className="progress row justify-center">
              <CircularProgress />
            </div>
          ) : (
            <div className="row">
              <div className="container">
                <div className="stemkit__container">
                  {props.match.params.id ? <StemKitItem orderStatus={props.match.params.order} id={props.match.params.id} 
                  getStemKit = {props.getStemKit} state = {props.stem_kit} /> : props.stem_kits.map((obj) =>
                   <Link onClick = {handleChange} to={'/stemkits/'+obj.id} className="stemkit__card">
                       <div className="stemkit__img"><img src={config.REACT_APP_ROOT + '/' + obj.images_attributes[0]?.file.url}></img></div>
                       <div className="stemkit__text">
                          <p className="stemkit__price">&euro;{obj.stem_kit_price}</p>
                          <p className="stemkit__name">{obj.title}</p>
                       </div>
                   </Link>  
                 )}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </StaticPage>
  )
}

export default StemKits