import React, {useEffect, useState} from 'react'
import { Link } from "react-router-dom";
import config from "../../config";
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import StemKitOrder from './StemKitOrder'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const pblsh_key = 'pk_test_51Hf5iGIQPspGj7cCEt6S1Pl4kEeluS6hteJLXmB3vW041ezRzjxM766Dev0tWPVsT8UGJgIK1Wfaqu5QDq6RAuhE00QeeRgshu'

const stripePromise = loadStripe(pblsh_key)

const StemKitItem = props => {
    useEffect(() => {
        if(typeof props.orderStatus === 'undefined'){
            localStorage.setItem('count', 1)
            localStorage.setItem('ordering_type', 0)  
        }
        console.log(props)
    }, [])
    const [order, setOrder] = useState({count:parseInt(localStorage.count), ordering_type:parseInt(localStorage.ordering_type), stem_kit_id: Number(props.id)})
    const handleChangeQuantity = e => {
        if(e === 'add') setOrder({ ...order, count: order.count + 1 })
        else if(e === 'remove') if(order.count > 1) setOrder({ ...order, count: order.count - 1 })
    }
    const handleChange = e => {
        let name = e.target.name, value = Number(e.target.value)
        setOrder({ ...order, [name]: value })
        if(name === 'count' && value < 1) setOrder({ ...order, [name]: 1})
        console.log(order)
    }

    const handleSubmit = () => {
        localStorage.setItem('count', order.count)
        localStorage.setItem('ordering_type', order.ordering_type)
    }

    return(
        <>
        {props.orderStatus 
            ?            
            <Elements stripe={stripePromise}>
                <StemKitOrder Order={order} stem_kit={props.state}/>
            </Elements>
            :
            <div className='stemkit-item__container'>
                <div className='stemkit-item__left'>
                    <div className='stemkit-item__left__images'>
                        {props.state?.images_attributes.map((obj, index) => <img className={`stemkit-item__left__images-img stemkit-item__left__images-img-${index}`} src={config.REACT_APP_ROOT + '/' + obj?.file.url}></img>)}
                    </div>
                </div>
                <div className='stemkit-item__right'>
                    <p className="stemkit__name">{props.state?.title}</p>
                    <p className="stemkit__price">&euro;{order?.ordering_type === 0 ? props.state?.stem_kit_price : order?.ordering_type === 1 ? props.state?.price + props.state?.stem_kit_price : 'ERROR'}</p>
                    <form className="stemkit__form">
                        <select className="stemkit__form__select" value={order?.ordering_type} onChange={e => handleChange(e)} name="ordering_type" id="order">
                            <option selected value="0">StemKit price</option>
                            <option value="1">Online access plus StemKit price</option>
                        </select>
                        <div className="stemkit__form__input">
                            <span name="count" onClick={e => handleChangeQuantity(e = 'add')} id="add" className="stemkit__form__input__icon stemkit__form__input__icon-add"><AddIcon /></span>
                            <span name="count" onClick={e => handleChangeQuantity(e = 'remove')} id="remove" className="stemkit__form__input__icon stemkit__form__input__icon-remove"><RemoveIcon /></span>
                            <input name="count" type="number" onChange={e => handleChange(e)} value={order.count} />
                        </div>
                        <Link className='add_course_btn' onClick={handleSubmit} to={props.id + '/order'}>Make an order</Link>                    
                    </form>
                    <p>{props.state?.description}</p>
                </div>
            </div>
        }
        </>
    )
}

export default StemKitItem
