import typeToReducer from 'type-to-reducer';
import { GET_STEM_KITS, GET_STEM_KIT } from './constants';

export const initialState = {
  stem_kits: [
    {
      "id":'',
      "title":"",
      "description":"",
      "stem_kit_price":'',
      "price":'',
      "images_attributes":[]
    },
  ],
  stem_kit: {
    "id":'',
    "title":"",
    "description":"",
    "stem_kit_price":'',
    "price":'',
    "images_attributes":[]
  }
};

export default typeToReducer({
  [GET_STEM_KITS]: {
    START: (state) => {
      return {
        ...state,
        inProcess: true
      }
    },
    SUCCESS: (state, action) => {
      return {
        ...state,
        stem_kits: action.payload.data.data.stem_kits,
        inProcess: false
      }
    },
    FAIL: (state, action) => {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        inProcess: false
      }
    }
  },
  [GET_STEM_KIT]: {
    START: (state) => {
      return {
        ...state,
        inProcess: true
      }
    },
    SUCCESS: (state, action) => {
      return {
        ...state,
        stem_kit: action.payload.data.data.stem_kit,
        inProcess: false
      }
    },
    FAIL: (state, action) => {
      return {
        ...state,
        errors: action.payload.response.data.errors,
        inProcess: false
      }
    }
  }
},
initialState,
)