import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import config from "../../config";
import api from '../../utils/api';
import { addStemKitOrderAction } from './actions'
import {
  CardElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { STRIPE_KEY, TOTAL_COST, SINGLE_COST, CURRENCY } from '../../containers/PaymentsInfo/constants'
import {
  errorNotificationAction,
  successNotificationAction,
} from "../Notifications/actions";
import { ROUTE_TO_STEM_KITS, ROUTE_TO_LOGIN, routeToUStemKit } from "../../constants/routes";
import { ContactsOutlined } from "@material-ui/icons";
import history from "../../history";

const StemKitOrder = ({
  stem_kit,
  user,
  Order,
  addOrder,
  successNotification,
  errorNotification
}) => {
  const [order, setOrder] = useState()
  const [createdOrder, setCreatedOrder] = useState()
  const [showModal, setShowModal] = useState()
  const [price, setPrice] = useState()
  const [completeCard, setCompleteCard] = useState(false)
  const [submitStatus, setSubmitStatus] = useState()
  const stripe = useStripe()
  const elements = useElements()
  useEffect(() => {    
    if (typeof user === 'undefined')   console.log(user)
  }, [user])
  useEffect(() => {
    setOrder({ ...Order, count: parseInt(localStorage.count), ordering_type: parseInt(localStorage.ordering_type) })
    console.log(order)
    setPrice(localStorage.ordering_type === '0' ? stem_kit.stem_kit_price*localStorage.count : stem_kit.price + stem_kit.stem_kit_price*localStorage.count)
  }, [Order, stem_kit])

  const handleChange = e => {
    let name = e.target.name, value = e.target.value
    setOrder({ ...order, [name]: value })
  }

  const iframeStyles = {
    base: {
      color: "#444",
      fontSize: "16px",
      "::placeholder": {
        color: "#666"
      }
    },
    invalid: {
      color: "#f88"
    },
    complete: {
      color: "#000"
    }
  };
  const cardElementOpts = {
    style: iframeStyles,
    hidePostalCode: true
  }

  const completeOrder = (clientSecret) => {
    stripe.retrievePaymentIntent(clientSecret)
    .then(res => {
      const paymentIntent = res.paymentIntent;
      if (paymentIntent.status === "succeeded") {
        successNotification('Payment succeded')
        setTimeout(() => {
          history.push(routeToUStemKit(order.stem_kit_id))
        }, 2000)
      }
    }).then(() => {
      setSubmitStatus(false)
    }).catch(res => console.log(res))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    if(completeCard) {
      setSubmitStatus(true)
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const payload = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });


      const payment_method = payload.paymentMethod.id
      // data.append('adress', order.adress)
      // data.append('apartment', order.apartment)
      // data.append('count', order.count.toString())
      // data.append('email', order.email)
      // data.append('first_name', order.first_name)
      // data.append('last_name', order.last_name)
      // data.append('ordering_type', order.ordering_type.toString())
      // data.append('phone', order.phone.toString())
      // data.append('postal_code', order.postal_code.toString())
      // data.append('stem_kit_id', order.stem_kit_id.toString())
      // data.append('token', payment_method)

      const data = {
        adress: order.adress,
        apartment: order.apartment,
        count: order.count,
        email: order.email,
        first_name: order.first_name,
        last_name: order.last_name,
        ordering_type: order.ordering_type,
        phone: order.phone,
        postal_code: order.postal_code,
        stem_kit_id: order.stem_kit_id,
        token: payment_method
      }

      addOrder({ordering:data}).then(async ans => {
        if(ans.value.status === 200) {
          api.post('/buy_stem_kits', {token: payment_method, ordering_id: ans.value.data.data.ordering.id}).then(res => {
            if(res.status === 200) {
              stripe.confirmCardPayment(res.data.payment_intent_client_secret, {
                payment_method: {
                  card: elements.getElement(CardElement)
                }
              }).then(() => {
                  completeOrder(res.data.payment_intent_client_secret)
                })
            }
          })}
      //     if (res.status === 200) {
      //       console.log(res)
  
      //     } else if (res.status === 400) {
      //       console.log('FAIL')
      //     }
      //   }
      })

      // data.delete('token')
    } else alert('FILL THE CARD INFO')
  }

  const handleCardChange = e => {
    if (e.complete) {
      setCompleteCard(true)
      setSubmitStatus(false)
    }
    else {
      setCompleteCard(false)
      setSubmitStatus(true)
    }
  }

  return (
    <div className="stemKitOrder-flex">
      <form onSubmit={e => handleSubmit(e)} className="stemKitOrder">
        <p className="stemKitOrder__text">Please, fill in all the fields to make an order</p>
        <div className="stemKitOrder__card">
          <div className="stemKitOrder__card__img"><img src={config.REACT_APP_ROOT + '/' + stem_kit.images_attributes[0]?.file.url} /></div>
          <div className="stemKitOrder__card__info">
            <p className="stemKitOrder__card__info_count">{order?.count}x</p>
            <p className="stemKitOrder__card__info_title">{stem_kit.title}</p>
            <p className="stemKitOrder__card__info_price">&euro;{price}</p>
          </div>
        </div>

        <input name="email" onChange={(e) => handleChange(e)} required className="stemKitOrder__input" placeholder="Email" type='email' />
        <input name="first_name" onChange={(e) => handleChange(e)} required className="stemKitOrder__input" placeholder="First name" type='text' />
        <input name="last_name" onChange={(e) => handleChange(e)} required className="stemKitOrder__input" placeholder="Last name" type='text' />
        <input name="adress" onChange={(e) => handleChange(e)} required className="stemKitOrder__input" placeholder="Adress" type='text' />
        <input name="apartment" onChange={(e) => handleChange(e)} className="stemKitOrder__input" placeholder="Apartment, suite, etc. (optional)" type='text' />
        <input name="postal_code" onChange={(e) => handleChange(e)} required className="stemKitOrder__input" placeholder="Postal code" type='number' />
        <input name="phone" onChange={(e) => handleChange(e)} required className="stemKitOrder__input" placeholder="Phone" type='number' />
        <div className='stemKitOrder__input'>
          <div className='stemKitOrder__input_div'>
            <CardElement onChange={handleCardChange} options={cardElementOpts}/>
          </div>
        </div>
        <input type="submit" onChange={(e) => handleChange(e)} className="stemKitOrder__button add_course_btn" value="Continue to payment" />
      </form>

    </div>
  )
}

const mapStateToProps = ({ auth: { user } }) => ({ user })

const mapDispatchToProps = dispatch => ({
  addOrder: (data) => dispatch(addStemKitOrderAction(data)),
  successNotification: (message) => dispatch(successNotificationAction(message)),
  errorNotification: (message) => dispatch(errorNotificationAction(message))
})

export default connect(mapStateToProps, mapDispatchToProps)(StemKitOrder)